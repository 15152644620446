.all {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-bottom: 50px;
  padding-right:20px;
}


h3 {
  text-align: center;
  color: #444649;
  font-weight: bold;
  font-size: 30px;
}

.skill {
  width: 90px;
  max-height: 100%;
  display: flex;
  align-items: center;
  margin: 5px;
  position: relative;
  float: left;
}
.skilltext {
  visibility: hidden;
  background-color: #ffffff;
  color: #663399;
  text-align: center;
  font-weight: bold;
  padding: 5px;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 50%;
  margin-left: -50px;
  opacity: 0;
  transition: opacity 0.5s;
}

.skill:hover .skilltext {
  visibility: visible;
  opacity: 1;
}
.logo {
  width: 90%;
  height: 100%;
  margin-left: 10%;
  margin-right: auto;
}

@media only screen and (max-width: 768px) {
  .all {
    max-width:100%;
    padding-left:40px;
    padding-right:0px;
  }
.logo{
  margin: auto;
}
.skill{
  width: 50px;
  max-height: 50%;
}
}
