body,
html {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.main {
  text-align: center;
}
h2 {
  text-decoration: underline;
}
.navbar {
  background-color: #1B2E44;
  height: 20px;
  padding: 10px;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 999;
}

.nav-text {
  display: flex;
  justify-content: space-between;
  width: 50%;
  padding-left: 50px;
  padding-right: 10px;
  font-weight: bold;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media only screen and (max-width: 768px) {
  .nav-text {
    padding-left: 15px;
  }
}
