input,
select {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

input[type="submit"] {
  width: 100px;
  background-color: purple;
  color: #ccc;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  float: right;
  font-weight: bold;
  font-size: 15px;
}
input[type="submit"]:hover {
  background-color: #45a049;
}
textarea {
  width: 100%;
  height: 50%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

a {
  color: rgb(238, 235, 235);
  padding: 10px;
  text-decoration: none;
}
.contact1 {
  display: flex;
  align-self: center;
  padding-top: 100px;
}

.page {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  min-height: 100vh;
  background-color: #0a0a29;
}

.contactTitle {
  display: flex;
  padding-top: 50px;
  padding-bottom: 5px;
  font-weight: bold;
  font-size: 43px;
  align-self: center;
  color: rgb(238, 235, 235);
}

.contactHr {
  display: block;
  align-self: center;
  height: 10px;
  width: 80px;
  border: 0;
  border-top: 4px solid #f5f5f5;
  margin: 0.5em 0;
  padding: 0;
  padding-bottom: 60px;
}

.triangle-up {
  width: 0;
  height: 0;
  border-left: 800px solid transparent;
  border-right: 800px solid transparent;
  border-top: 90px solid #f5f5f5;
}

.footer {
  color: white;
  padding: 50px;
  text-align: center;
  font-size: 14px;
}

.line {
  color: #24c9c9;
  font-weight: bold;
  margin-bottom: 2%;
  font-family: "HelveticaNeue-Light";
}

.formdesign {
  width: 50%;
}

.statusMessage {
  color: beige;
  font-size: 15px;
}
